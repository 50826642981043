<!--Módulo de Gestión de Avisos de Facturación -->

<!-- Avisos Facturación

funescom -> expediente -> avisos

fnt:        num, get, set
tip:         *get      1=avisos incidencias,2=recep.documentos
std:        *get      0,1 Pendientes, 2=Vistos
id:           *set       Id del aviso
txt:         *set       Texto respuesta observaciones
env:       *set       Envio Respuesta 0=no,1=email,2=sms
ema       *set       Email para envio
sms        *set       Telefono para sms -->

<template>
  <div class="avisosFacturas" v-if="loaded">
    <dualTemplate
      :dualTipo="sync? '':'modal'"
      :modal="modal"
      :storeName="storeRaiz"
       persistent>
      
        <template v-slot:controles="{}">    
          <v-sheet v-if="!viewMtoOnly" :elevation="4">

            <!-- Cabecera -->
            <div class="cabecera" v-if="!viewMtoOnly" >     
              <baseHeader
                :cfg="$cfe.headers.finder"
                :titulo='$store.state[storeName].titulo'
                @onEvent="cerrar"> 
              </baseHeader>
            </div>
        
            <!-- Contenido -->
            <div class="contenedor" style="width:1200px">      
     
              <!-- Botonera Extra -->
              <baseBtraExtra
                style="width:200px"
                :permExtra="permExtra"
                :modulo="btExtCfg"           
                @onEvent="execAccion">                          
              </baseBtraExtra>
         
              <!-- Filtro Búsqueda -->
              <div class="conflex" style="margin-top:10px">            
                <v-select
                  style="width:30%"
                  v-bind="$select"
                  v-model="ct.estado[2]"
                  :label="ct.estado[1]"            
                  :items="itemsEstados"
                  item-value="id"
                  item-text="name"                  
                  @change="changeEstado">                  
                </v-select>

                <uiText
                  style="width:49%;margin-left:5px"
                  v-bind="$input"
                  v-model="ct.txt[2]"
                  :label="ct.txt[1]"
                  append-icon="mdi-magnify"
                  @Enter="filtrar(ct.txt[2])"
                  @click:append="filtrar(ct.txt[2])"> 
                </uiText>

                <div v-if="ndep>= 6" style="width:20%;margin-left:5px" >
                  <v-select                 
                    v-bind="$select"
                    v-model="ct.tipo[2]"
                    label="Tipo"            
                    :items="itemsTipo"
                    item-value="id"
                    item-text="name"
                    @change="filtrar(ct.txt[2])">                                   
                  </v-select>
                </div>  
              </div>            

              <v-sheet v-bind="$cfg.styles.marco">

                <!-- Grid -->
                <baseGridMD
                  :store="storeName"
                  :headers="headerGrid"
                  :items-per-page="100"   
                  fixed-header             
                  show-select 
                  dense
                  height="410px" 
                  @onEvent="execAccion">

                    <!-- Slot botonera fallename -->
                    <template v-slot:fallename="{ item }">              
                      {{ item.fallename }}
                      <div class="light-blue--text text--darken-3"> {{ item.cta }}</div>
                    </template>

                    <!-- Slot botonera fra -->
                    <template v-slot:fra="{ item }">    
                      {{ item.fra }}
                      <div class="light-blue--text text--darken-3"> {{ item.fhf|fecha }}</div>
                    </template>

                    <!-- Slot botonera documento -->
                    <template v-slot:documento="{ item }">    
                      {{ item.nom }}
                      <div class="light-blue--text text--darken-3"> {{ item.obs }}</div>
                      <div class="light-green--text text--darken-3"> {{ item.visto_obs }}</div>
                    </template>

                </baseGridMD>
              </v-sheet>
            </div> 
          </v-sheet>


          <!-- Ventana de componente Expedientes (TEMPORAL) -->
          <v-dialog
            v-model="modalExp"
            content-class="modal"
            persistent>
              <div class="centrado">
                <component                 
                  :is="componente_dinamico"
                  :padre="storeName"
                  :accion=0
                  :accionRow="{ id:syncRecord.exp_id }"
                  :disparo="disparo"                
                  @onEvent="$event.accion=='cerrar' || $event.accion==6? cerrar_dinamico() : ''">
                </component>           
              </div>
          </v-dialog>


          <!-- Ventana de Componentes Extra  -->
          <!-- paso record expediente en la propiedad recordAux para estandarizarlo -->
          <component      
            :is="componenteDinamico"
            :storeRaiz="storeName"
            :masterStore="storeName"
            :masterRecordField="fieldAux"            
            :filtro="filtroAux"
            tipoFiltro="exp"
            @onEvent="$event.accion==6 || $event.accion=='salir'? componenteDinamico=null : ''">
          </component>          
        </template>
    </dualTemplate>
  </div>
</template>



<script>

  import { mixF } from "@/mixins/mixF.js";
  import baseHeader from "@/base/baseHeader";
  import baseGridMD from "@/base/baseGridMD";
  import dualTemplate from "@/components/dualTemplate";
  import baseBtraExtra from "@/base/baseBtraExtra";
  import exp_M from "@/components/exp_M";
  import frasExpF from "@/components/frasExpF";
  import avisosFacturasEmail from "@/components/avisosFacturasEmail"; 
    
  export default {
    mixins: [mixF],
    components: { baseHeader, baseGridMD, dualTemplate, baseBtraExtra, exp_M, frasExpF, avisosFacturasEmail },
    props: {},

    data() {
      return {
        stIni: {
          api: "avisosFacturasF",
          titulo:"Avisos Documentación", 
          name:"avisosFacturasF",
          mView:'',
          pView:[]
        },
              
        itemsEstados: [],
        itemsTipo: [],
        fieldAux:'',
        filtroAux:'',

        // variables temporales para mostrar exp_M 
        componente_dinamico:null,          
        disparo:false,
        modalExp:false

      };
    },


    methods: {
      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");             
  
        this.headers = [{
          name:[],
          fields:[        
            { text: "Fecha", value: "fh", witdh: "10%", filtro:"fechahr" },
            { text: "Control", value: "control", witdh: "10%" },
            { text: "Fallecido / Proveedor", value: "fallename", witdh: "10%", slot:true },
            { text: "Factura", value: "fra", witdh: "10%", slot:true },
            { text: "Documento / Obs", value: "documento", witdh: "10%", slot:true },
          ]}
        ];

        this.headerGrid= this.headerSet();

        // obtengo datos de GRID
        this.autoLoad= 'buscar';

        // añado botones extra
        this.btExtCfg.btnsAccion= [
          { accion:"expedientes", icono: "mdi-alpha-e-circle-outline", title:'Ver/Editar Expediente' },
          { accion:'facturacion', icono: "mdi-alpha-f-circle-outline", title:'Facturación' },
          { accion:'verDocumento', btn: "pdf", title:'Ver documento' },
          { accion:'visto', icono: "mdi-check",  title:'Visto / OK' },
          { accion:'vistoEmail', btn: "sendEmail" },
        ];
             
        // array de estados de los avisos
        this.itemsEstados= [
          { id:"1", name:'Pendientes' },
          { id:"2", name:'Vistos / OK' }
        ];

        // array de tipos de avisos
        this.itemsTipo= [
          { id:"0", name:'(Todos)' },
          { id:"1", name:'CTD' },
          { id:"2", name:'ADMON' }
        ];
      },


      //
      iniDataApi() {
        this.apiArgs.sch= null;
        this.apiArgs.get= ["", 'get', { tip:2, std:'0' }, "avisos"]; 
        this.apiArgs.visto= ["", 'visto', { tip:2, id:0 }, "avisos"]; 
        this.apiArgs.fichero= ["", 'fichero', { tip:0, doc:'' }, "documento"];       
      },


      // (override). apiCall recordsGet
      async stRecordsGet(record, busqueda) {
        // apiArg: llamada API definida en el particular. 
        // args: argumentos a fusionar con los definidos en 'args' de apiArgs

        let param = { apiArg: this.apiArgs.get, args: { std:this.ct.estado[2], ctrls: record, accion: busqueda } };
        console.log("stRecordsGet *************************:", param);
        return await this.$store.dispatch(this.storeName + "/recordsGet", { param, masterStore: this.masterStore });
      },


      // configuro estado botones
      botonerasSet() {
        this.btnSet(this.btExtCfg, "expedientes", { disabled:!this.syncRecord.id });
        this.btnSet(this.btExtCfg, "facturacion", { disabled:!this.syncRecord.id });        
        this.btnSet(this.btExtCfg, "verDocumento", { disabled:!this.syncRecord.id });
        this.btnSet(this.btExtCfg, "visto", { disabled:!this.syncRecord.id || this.syncRecord.visto });
        this.btnSet(this.btExtCfg, "vistoEmail", { disabled:!this.syncRecord.id });     
      },


      // muestro ventana expedientes
      async expedientes() {        
        // cierra la ventana de expedientes si ya se encuentra abierta        
        if (this.$router.currentRoute.name=='exp_M') {
          await this.$router.push({ path:'/' });
        }
                
        this.componente_dinamico= "exp_M";
        this.disparo= !this.disparo;
        this.modalExp= true;
      },


      // componente dinámico facturación
      facturacion() {
        this.fieldAux= 'id';
        this.filtroAux= this.syncRecord.control;
        this.componenteDinamico= "frasExpF";      
      },


      // API para obtener el fichero (fra/doc) asociado al id recibido
      async verDocumento() {
        let param = { apiArg: this.apiArgs.fichero, args: { doc:this.syncRecord.doc }};
        let apiResult = await this.$store.dispatch("apiCall", param);
        
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        // abro documento
        window.open(this.$store.state.G.url_tmpNV + apiResult.r[0], "_blank"); 
      },


      // 
      vistoEmail() {
        this.fieldAux= 'exp_id';
        this.componenteDinamico='avisosFacturasEmail';
      },


      // marco como visto 
      visto() {
        this.$root.$alert.open('VISTO / OK Documento<br>¿Aceptamos como VISTO el Documento recibido?' , 'confirm', null, ["ACEPTAR", "CANCELAR"])
        .then(r=> { if (r==0) this.vistoContinue() });
      },

      async vistoContinue() {
        let param = { apiArg: this.apiArgs.visto, args: { id:this.syncRecord.id }};
        let apiResult = await this.$store.dispatch("apiCall", param);
        
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }
       
        // actualizo records
        this.$store.commit(this.storeName + '/recordSet', apiResult.r[0]);
      },


      // cambio el select Estado
      async changeEstado() {
        await this.recordsGet();
        this.filtrar(this.ct.txt[2]);
      },


      // filtrar registros por tipo de documento
      async filtrar(txt) {
        await this.stFiltrar(this.filtrarRecords, { txt });        
      },


      // filtrar por el campo de texto
      filtrarRecords(records, params) {
        return records.filter(elem=> {          
          return ((this.ct.tipo[2]== 0? elem : elem.tipo== this.ct.tipo[2]) && 
                 (params.txt?
                 (elem.control.toLowerCase().includes(params.txt.toLowerCase()) ||
                 elem.fallename.toLowerCase().includes(params.txt.toLowerCase()) ||
                 elem.nom.toLowerCase().includes(params.txt.toLowerCase()) ||                 
                 elem.cta.toLowerCase().includes(params.txt.toLowerCase()) ||
                 elem.fra.toLowerCase().includes(params.txt.toLowerCase())) : true));        
        });
      },


      // cierro componente dinámico de Expedientes (TEMPORAL)
      cerrar_dinamico() {        
        this.modalExp= false;
        this.componente_dinamico= null;
      },


      // cierro ventana avisos
      cerrar() {
        this.$emit('aviso', { accion:null });

        // obtengo los avisos de expedientes
        if (this.$root.$avisos_campana) this.$root.$avisos_campana.recuperaN();
      }

    },


    computed: {
      sch() {
        return {
          estado: [ 'estado', 'Estado', '1' ],
          txt: [ 'txt', 'Busqueda', '' ],
          tipo: [ 'tipo', '', '0' ] 
        }
      }
    },


    watch: {
      syncRecord() {        
        this.botonerasSet();
      }
    }
  };
</script>
